<template>
  <div>
    <div class="app-main flex-column flex-row-fluid " id="kt_app_main">
      <!--begin::Content wrapper-->
      <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Content-->
        <div id="kt_app_content" class="app-content  flex-column-fluid ">
          <!--begin::Content container-->
          <div id="kt_app_content_container" class="app-container  container-xxl ">
            <!--begin::Post card-->
            <div class="card">
              <!--begin::Body-->
              <div class="card-body p-lg-20 pb-lg-0">
                <!--begin::Layout-->
                <div class="d-flex flex-column flex-xl-row">
                  <!--begin::Content-->
                  <div class="flex-lg-row-fluid me-xl-15">
                    <!--begin::Post content-->
                    <div class="mb-17">
                      <!--begin::Wrapper-->
                      <div class="mb-8">

                        <!--begin::Title-->
                        <div href="#" class="text-gray-900 fs-2 fw-bold" style="text-align: center">
                          PRIVACY POLICY
                        </div>
                        <!--end::Title-->
                      </div>
                      <!--end::Wrapper-->

                      <!--begin::Description-->
                      <div class="fs-5 fw-semibold text-gray-600">

                        <p class="mb-8 text-gray-900">
                          1. A short introduction: What is Wanted Crew International and what is the
                          purpose of this document?
                        </p>

                        <p class="mb-8">
                          Wanted Crew International (“Wanted” or “The Company”) provides its clients with an
                          all-inclusive, excellent crewing service and takes care of the working circumstances for
                          the team.
                        </p>

                        <p class="mb-8">
                          We are devoted to delivering three core goals: enabling qualified individuals across
                          Europe to find jobs for which they are best suited, offering our customers qualified
                          personnel capable of providing remarkable experiences during their events and forging
                          relationships with reliable partners so that everyone can have complete confidence in the
                          quality of the booked crew.
                        </p>

                        <p class="mb-8">
                          In order to achieve it, Wanted uses its own web application, which has enhanced the
                          hiring, booking, and scheduling of all employees and gained expertise deploying both
                          small and large teams of technicians, staff, and crew alongside various productions.
                        </p>

                        <p class="mb-8">
                          The aim of this Privacy Policy is to provide all users of our web application -
                          <a href="https://app.wantedcrew-int.com/">https://app.wantedcrew-int.com/</a> (<span class="fw-bold text-gray-900">“The Website”</span>), as well as our two other sites -
                          <a href="https://www.wci.dk/">https://www.wci.dk/</a> and <a href="https://www.wantedint.com/">https://www.wantedint.com/</a> with the legally required
                          information on how their personal data is being processed by Wanted for the purposes
                          of managing the web application.
                        </p>

                        <p class="mb-8">
                          This Privacy Policy applies to all users, regardless of their role in the website as long as
                          they constitute a “data subject” in the sense of the General Data Protection Regulation
                          (GDPR).
                        </p>

                        <p class="mb-8 text-gray-900">
                          2. What is “personal data”?
                        </p>
                        <p class="mb-8">
                          Personal data is information that directly or indirectly identifies you as an individual (a
                          natural person); indirectly meaning when it is combined with other information such as
                          your names, postal address, e-mail address, telephone number, website preferences, etc.
                        </p>
                        <p class="mb-8 text-gray-900">
                          3. Definitions
                        </p>
                        <p class="mb-8">
                          Where a legal term connected to data processing is used in this Privacy Policy (such as
                          “personal data”, “data controller”, “data processor”, “transfer of data”, “consent for data
                          processing”, “special categories of data”, etc.), it is used in the sense and has the
                          meaning under the General Data Protection Regulation (GDPR).
                        </p>
                        <p class="mb-8">
                          Additionally, this Privacy Policy uses the following terms which shall have their specific
                          definitions hereunder:
                        </p>
                        <ul class="mb-8">
                          <li>
                            “Users” shall mean all types of users of The Website, including freelancers (self-
                            employed and hired by Wanted to work for different clients of Wanted on particular
                            assignments), employees of Wanted’s clients, as well as both registered users and
                            non-registered users;
                          </li>
                          <li>
                            “Workers” shall all mean the same thing, and namely – the persons that are
                            contracted by Wanted and required to work on different assignments in favor of
                            Wanted’s clients, while being engaged via The Website;
                          </li>
                          <li>
                            “Clients” shall mean employees of Wanted’s clients who use the website in order to
                            find and select workers.
                          </li>
                          <li>
                            “The Website” shall mean <a href="https://app.wantedcrew-int.com/">https://app.wantedcrew-int.com/</a> . Тhe same policy
                            applies to two other websites managed by the Company - <a href="https://www.wci.dk/">https://www.wci.dk/</a>
                            and <a href="https://www.wantedint.com/">https://www.wantedint.com/</a>;
                          </li>
                          <li>
                            “Data Controller” shall mean Wanted LLC. As a data controller, Wanted determines
                            the purposes and means of the processing of personal data.
                          </li>
                        </ul>
                        <p class="mb-8 text-gray-900">
                          4. How do we process your personal data?
                        </p>
                        <p class="mb-8">
                          We at Wanted LLC, with its seat in the Kingdom of Denmark, the city of Copenhagen,
                          and management address at 42 Nørrebrogade Str., CRV number 25250060, (hereinafter
                          shortly referred to only as “Wanted”) - the website administrator of <a href="https://app.wantedcrew-int.com/">https://app.wantedcrew-int.com/</a>,
                          will use your personal data only for the purposes described in the next section of this
                          Privacy Policy.
                        </p>
                        <p class="mb-8">
                          Rest assured that we do not collect or process more or other types of personal data than
                          we absolutely need to fulfill the purposes set out in this Privacy Policy.
                        </p>
                        <p class="mb-8">
                          We will only use personal information as set out in this policy unless you have given us
                          your explicit consent to another use of your personal information.
                        </p>
                        <p class="mb-8 text-gray-900">
                          5. What types of personal data do we collect and process? What are the legal
                          grounds and for processing the collected data?
                        </p>
                        <p class="mb-8">
                          Below are the types of personal data being processed by Wanted through The Website.
                          They are the following:
                        </p>
                        <ul class="mb-8">
                          <li>
                            Full legal names;
                          </li>
                          <li>
                            E-mail address;
                          </li>
                          <li>
                            Phone number;
                          </li>
                          <li>
                            Country and city of residence;
                          </li>
                          <li>
                            Emergency contact information;
                          </li>
                          <li>
                            Professional experience;
                          </li>
                          <li>
                            Payment information (Social security number, IBAN & SWIFT);
                          </li>
                          <li>
                            Prior professional experience and professional skills;
                          </li>
                          <li>
                            IP address and type of device used to access The Website;
                          </li>
                          <li>
                            Additional website preferences that The User chooses to change in the Settings
                            menu and web-browsing history being gathered via the Wanted platform cookies
                            and automatically processed afterwards (for more information please visit our
                            <router-link to="/cookie-policy" class="link-primary fw-bolder">Cookie Policy</router-link>);
                          </li>
                          <li>
                            Profile picture – workers have the option to attach a profile picture to their
                            profile;
                          </li>
                          <li>
                            CV - workers have the option to attach a CV/Resume picture to their profile;
                          </li>
                          <li>
                            Documents and all the personal data listed in the respective document – namely
                            Driver’s license, Forklift license, First aid certificate, Master Joggler Certificate,
                            Drone certificate, as well as other certificates and qualification related
                            documents – workers may upload them (upon their free will and decision to do
                            so) in order to demonstrate their skills to potential contractors. The website does
                            not require the Workers to upload specific documents but simply provides the
                            option for them to do so.;
                          </li>
                          <li>
                            Any other information which The User may choose to share with Wanted or with
                            another User through the live chat on the webpage. Typically, this information
                            will be questions on working projects.
                          </li>
                        </ul>
                        <p class="mb-8">
                          Please note that necessary personal information needed for account creation are the
                          following: first and last name, email, country and city of residence and a valid phone
                          number.
                        </p>
                        <p class="mb-8">
                          Wanted processes the collected data for the following purposes and on the following
                          legal grounds:
                        </p>
                        <table class="mb-8">
                          <tr>
                            <th>N</th>
                            <th>Type of data processing activity</th>
                            <th>Purpose for data processing</th>
                            <th>Legal grounds under GDPR</th>
                          </tr>
                          <tr>
                            <td>
                              1.
                            </td>
                            <td>
                              Gathering of personal information on
                              non-registered Users of The Website, including IP
                              address and how they use the website
                            </td>
                            <td>
                              Developing and maintaining the Web application of
                              Wanted – the main business interest and activity
                              of the Company
                            </td>
                            <td>
                              Legitimate interests of the Data Controller Art.
                              6, para 1, (e) GDPR
                            </td>
                          </tr>
                          <tr>
                            <td>2.</td>
                            <td>
                              Processing of data of Users, necessary for their
                              registration and use of the website services as a
                              registered user
                            </td>
                            <td>
                              Account creation and use of The Website as a
                              registered User
                            </td>
                            <td>
                              Consent for the specific purpose Art. 6, para 1,
                              (a) GDPR
                            </td>
                          </tr>
                          <tr>
                            <td>3.</td>
                            <td>
                              Processing personal data of Workers when using The Website
                            </td>
                            <td>
                              Fulfilling the requirements for Wanted’s clients to be able to see the necessary details about the Workers and engage them with a specific task, pursuant to the Worker’s desire and availability.
                            </td>
                            <td>
                              Processing is necessary for the performance of a contract to which the data subject is party Art. 6, para 1, (b) GDPR
                            </td>
                          </tr>
                          <tr>
                            <td>4.</td>
                            <td>
                              Processing of geolocation
                            </td>
                            <td>
                              Improving the services of the Website and providing The Users with additional information on jobs located near them, which may interest them
                            </td>
                            <td>
                              Consent for the specific purpose Art. 6, para 1, (a) GDPR
                            </td>
                          </tr>
                          <tr>
                            <td>5.</td>
                            <td>
                              Processing of information using marketing and statistical (“non-essential”) cookies
                            </td>
                            <td>
                              Improving the services of The Website and providing Users with additional personalized information on nearby or similar events, which may interest them
                            </td>
                            <td>Consent for the specific purpose Art. 6, para 1, (a) GDPR</td>
                          </tr>
                          <tr>
                            <td>6.</td>
                            <td>
                              Sending e-communication to the Workers regarding work engagements in which they are involved or may be interested.
                            </td>
                            <td>
                              Sending e-communication to the Workers regarding jobs in which they may be interested or regarding upcoming work projects that they may be interested in taking participation.
                            </td>
                            <td>
                              Legitimate interests of the Data Controller Art. 6, para 1, (e)
                            </td>
                          </tr>
                        </table>

                        <p class="mb-8">
                          Further clarifications on the purposes of the data processing:
                        </p>
                        <p class="mb-8 fw-bold">
                          A. <u>Profiling Activities:</u>
                        </p>
                        <p class="mb-8">
                          Wanted does not undertake automated profiling actions on its Users and does not carry
                          out estimations and predictions on what each Registered User may prefer. However, we
                          might contact a Registered User in order to provide information about a working
                          engagement which we may find relevant to his or her qualification. <u>If you, as a User, do
                          not wish to be subject to such profiling based on your professional experience or you are
                          not willing to be contacted, please object to in a written form on the following address:</u>
                          address:
                          <a href="mailto:privacy@wci.dk">privacy@wci.dk</a>
                        </p>
                        <p class="mb-8 fw-bold">
                          B. <u>Direct marketing:</u>
                        </p>
                        <p class="mb-8">
                          Wanted may also send Users automated direct e-communication (including direct
                          marketing messages) regarding job offers in which they may be interested or regarding
                          upcoming working projects that they might take part in. As required by law, Users are
                          provided with an opt-out (unsubscribe) option, so that they are in control of the process
                          and in the use of their personal data for this purpose.
                        </p>
                        <p class="mb-8 fw-bold">
                          C. <u>Website Search Toolbar:</u>
                        </p>
                        <p class="mb-8">
                          The Website has a built-in Search Toolbar where all Registered Users may search job
                          names, crew handlers, companies, invoices and venues which are associated with the
                          respective Registered User. The names of the crew handlers shall appear, and the
                          Company hereby informs all employes of Company’s clients that their names as project
                          managers are required for the use of the Website and for the provision of the essential
                          services of the Website.
                        </p>
                        <p class="mb-8 fw-bold">
                          D. <u>Personal data processing for regulatory compliance and assisting authorities:</u>
                        </p>
                        <p class="mb-8">
                          Wanted is also required and saves the right to further process and store Users’ personal
                          data on where compelling public interest applies and in order to achieve regulatory
                          compliance, such as to aid authorities in preventing, establishing and/or investigating
                          crimes, in order to prevent loss, fraud or other financial misconduct, etc.
                        </p>
                        <p class="mb-8 fw-bold">
                          E. <u>Processing of payment or banking information:</u>
                        </p>
                        <p class="mb-8">
                          Lastly, please note that Wanted processes personal data regarding Users’ payment and
                          bank details (limited to bank accounts information), since the payment process requires
                          it and Workers have to receive their due remuneration.
                        </p>
                        <p class="mb-8 text-gray-900">
                          6. Use of The Website:
                        </p>
                        <p class="mb-8">
                          This Privacy Policy also applies to your use of our website <a href="https://app.wantedcrew-int.com/">https://app.wantedcrew-int.com/</a> with the
                          following mechanics and features related to the protection of personal data:
                        </p>
                        <ul class="mb-8">
                          <li>
                            (1) Cookies: Our Website uses cookies and other technologies to improve the user's
                            work and functioning of The Website, usability, and security, as well as for the
                            purposes of research related to advertising effectiveness. Please consult our
                            Cookie Policy for more details.
                          </li>
                          <li>
                            (2) Third Party Websites: For the convenience of our visitors, our Website may
                            contain links to various websites that are not linked, controlled or managed by
                            us. The policies and procedures we describe here do not apply to these websites.
                            We are not responsible for the security or protection of any data collected by
                            these third parties. Our policies apply only to the websites listed above <a href="https://app.wantedcrew-int.com/">https://app.wantedcrew-int.com/</a>,
                            <a href="https://www.wci.dk/">https://www.wci.dk/</a> and <a href="https://www.wantedint.com/">https://www.wantedint.com/</a>. Concerning any other website - we
                            encourage you to contact these websites directly for information on their data
                            protection policies.
                          </li>
                        </ul>
                        <p class="mb-8 text-gray-900">
                          7. With whom do we share your personal data?
                        </p>
                        <p class="mb-8">
                          We will disclose your personal data only for the purposes and only to those third parties
                          listed below - unless we have received additional consent from you to transfer personal
                          data to other categories of third parties given elsewhere, such as by declaration of
                          consent for a specific purpose. Wanted will take appropriate measures to ensure that
                          your personal data is processed, protected and transferred in accordance with applicable
                          law.
                        </p>
                        <ul>
                          <li>
                            <p class="mb-8">
                              (1) <u>External service providers:</u>
                            </p>
                            <p class="mb-8">
                              When necessary, we engage other companies and individuals to perform certain tasks on
                              our behalf, such as specialized companies to maintain The Website and companies which
                              provide cloud services for storage of information. We will share or give access to such
                              information to external service providers only to the extent necessary for the respective
                              purposes. This information cannot be used by them for any other purpose, in particular
                              neither for their own purposes nor for the purposes of third parties. The external service
                              providers of Wanted are obliged by law or by contract to observe the confidentiality and
                              integrity of your personal data.
                            </p>
                          </li>
                          <li>
                            <p class="mb-8">
                              (2) <u>Transformation of a commercial company and purchase of shares or Wanted’s
                              subsidiaries:</u>
                            </p>
                            <p class="mb">
                              In connection with any potential transformation, restructuring, merger, sale or other type
                              of transfer of shares or assets, Wanted may transfer information, including personal data,
                              on a reasonable scale to the acquirer of these shares or assets, and as necessary for the
                              transfer of ownership, after the receiving party agrees to process your personal data and
                              to apply the necessary measures for their protection in a manner that complies with the
                              applicable European legislation.
                              We also share information (including personal data of Users) with subsidiaries of Wanted
                              based in different countries where Wanted operates.
                            </p>
                          </li>
                          <li>
                            <p class="mb-8">
                              (3) <u>Public authorities:</u>
                            </p>
                            <p class="mb-8">
                              We have the obligation to disclose your personal data to public authorities when required
                              by law. For example, Wanted will comply with the request and orders of courts,
                              administrative and other public state or municipal bodies, which may include similar
                              bodies of state authority.
                            </p>
                          </li>
                          <li>
                            <p class="mb-8">
                              (4) <u>Clients of Wanted:</u>
                            </p>
                            <p class="mb-8">
                              Contractors that use the Website have access to some of the Users personal data (namely
                              – identification information and information related to their work experience, as well as
                              contact information and the documents uploaded by the User).
                            </p>
                          </li>
                        </ul>
                        <p class="mb-8 text-gray-900">
                          8. Processing of personal data of children
                        </p>
                        <p class="mb-8">
                          Wanted does not collect or process personal data of children under the age of 16
                          (sixteen), except with the consent of a parent or guardian in accordance with applicable
                          local law. If we learn that a child's personal data has been accidentally collected, we will
                          immediately delete all data, connected to it.
                        </p>
                        <p class="mb-8 text-gray-900">
                          9. Processing of special categories of personal data
                        </p>
                        <p class="mb-8">
                          Wanted does not process special categories of personal data, such as the
                          following: personal data revealing racial or ethnic origin, political views, religious or
                          philosophical beliefs or trade union membership, as well as the processing of genetic
                          data, biometric data for the sole purpose of identifying an individual, data on the health
                          condition or data of the sexual life or sexual orientation of the individual. If we learn that
                          such categories of personal data have been accidentally collected, we will delete them
                          immediately.
                        </p>
                        <p class="mb-8 text-gray-900">
                          10. Security and integrity of your personal data:
                        </p>
                        <p class="mb-8">
                          Wanted takes the security of your personal data seriously. We apply the appropriate level
                          of protection and to this end we have developed reasonable physical, electronic and
                          technical procedures to protect the data we collect from accidental or unlawful
                          destruction, loss, alteration, unauthorized disclosure or access to transmitted, stored or
                          otherwise processed personal data. Access to your personal data is allowed only to those
                          employees who need it to perform their official duties and obligations within our
                          structures. In the unlikely event of a personal data beach, Wanted will follow all applicable
                          rules for notification in such cases in accordance with the applicable legislation.
                        </p>
                        <p class="mb-8 text-gray-900">
                          11. What are your legal rights related to your personal data?
                        </p>
                        <p class="mb-8">
                          As a data subject, you have specific legal rights related to the personal data we collect
                          from you. This applies to all processing activities that Wanted performs with your personal
                          data and which are listed above. Wanted respects your individual rights and will always
                          answer your questions and inquiries regarding the ways in which we process the personal
                          data received from you.
                        </p>
                        <p class="mb-8">
                          The list that follows contains information about your rights arising from the applicable
                          data protection laws:
                        </p>
                        <ul class="mb-8">
                          <li>
                            Right of correction: You have the right to correct personal data concerning you. We
                            make reasonable efforts to ensure that the personal data at our disposal and under our
                            control, which is regularly processed, is accurate, complete, up-to-date and relevant,
                            based on the latest information available to us.
                          </li>
                          <li>
                            Right to restriction: You have the right to restrict the processing of your personal
                            data if:

                            <ul>
                              <li>
                                Challenge the accuracy of your personal data for the period we need to verify their
                                accuracy;
                              </li>
                              <li>
                                The processing is unlawful, and you request a restriction on the processing instead of
                                deleting your personal data;
                              </li>
                              <li>
                                We no longer need your personal data, but you require them for the establishment,
                                exercise or protection of rights and legitimate interests; or
                              </li>
                              <li>
                                Objections to the processing pending verification that our legal grounds for said
                                processing take precedence over your rights and legal interests.
                              </li>
                            </ul>
                          </li>
                          <li>
                            Right of access: You have the right to ask us for information about the personal data
                            about you that we store, including information about what categories of personal data
                            we have or control, what they are used for, where we received them, if not directly from
                            you, and to whom they have been disclosed, if applicable. You have the right to a free
                            copy of the personal data we hold about you. Under current law, we reserve the right to
                            charge a reasonable fee for each additional copy after the first one you may request.
                          </li>
                          <li>
                            Right of portability: At your request, we will transfer your personal data to another
                            controller when technically feasible, provided that the processing is based on your
                            consent or contractual obligation. Instead of receiving a copy of your personal
                            information, you may request that we transfer it directly to another data controller you
                            specify.
                          </li>
                          <li>
                            Right to delete: You have the right to delete your personal data if:
                            <ul>
                              <li>
                                Personal data are no longer needed for the purposes for which they were collected or
                                otherwise processed;
                              </li>
                              <li>
                                You have the right to object to the further processing of your personal data (see below)
                                and exercise this right to object to the processing;
                              </li>
                              <li>
                                The processing is based on your consent; you withdraw this consent and there is no
                                other legal basis for the processing;
                              </li>
                              <li>
                                Personal data have been processed illegally,
                              </li>
                            </ul>
                            <u>Unless</u> processing is necessary for any of the following reasons:
                            <ul>
                              <li>To comply with a legal obligation that requires processing by us;</li>
                              <li>In particular, for archiving purposes in the public interest;</li>
                              <li>For the establishment, exercise or protection of rights and legitimate interests.</li>
                            </ul>
                          </li>
                          <li>
                            Right to object to further processing of personal data: You can object at any
                            time to the processing of your personal data in connection with your specific situation, in
                            cases where the processing is not based on our legal obligation to do so. This right also
                            applies to any and all profiling activities, carried out by Wanted in order to offer you the
                            best possible personalized experience on The Website. In such a case, we terminate all
                            active actions for the processing of your personal data, unless we prove that there are
                            compelling legal grounds that take precedence over your interests, or for the
                            establishment, exercise or protection of legal claims. If you object to the processing,
                            please specify whether you wish to delete your personal data or restrict the processing
                            by us.
                          </li>
                          <li>
                            Right to file a complaint: In case of an alleged violation of the laws applicable to
                            personal data protection, you can file a complaint to the Lead Supervisory Authority for
                            personal data protection for Wanted which is the Danish Data Protection Commission
                            (<a href="https://www.datatilsynet.dk/">https://www.datatilsynet.dk/</a>) or to the supervisory authority in the country where you
                            reside.
                          </li>
                        </ul>
                        <p class="mb-8">
                          <u>Additional information needed to exercise your rights.</u>
                        </p>
                        <ul>
                          <li>
                            Deadline: We will try to fulfill your requests for exercising rights or requesting
                            information from us concerning your personal data within the statutory period of 30
                            (thirty) days, and this period may be extended for specific reasons related to the specific
                            legal right, or due to the complexity of your request, of which we will notify you before
                            the expiration of this period.
                          </li>
                          <li>
                            Restriction of access: In certain situations, we may not be able to give you access
                            to all or part of your personal data due to legal provisions. If we deny your request for
                            access, we will notify you of the reason for that denial.
                          </li>
                          <li>
                            mpossibility of identification: In some cases we may not be able to verify your
                            personal data based on the identifiers provided in your request. In such cases, when we
                            cannot identify you as a data subject, we do not have the objective opportunity to comply
                            with your request to exercise your legal rights as set out in this section; unless you provide
                            us with additional information that would allow us to identify you in a satisfactory manner.
                          </li>
                          <li>
                            Exercising your legal rights: To exercise your legal rights, please contact us at the
                            addresses listed at the end of this policy.
                          </li>
                        </ul>
                        <p class="mb-8 text-gray-900">
                          12. Retention periods:
                        </p>
                        <p class="mb-8">
                          We store all collected personal data only to the extent that we need it.
                          Most of the data, which is related to The User’s activity in events of being assigned with
                          a job, is promptly deleted upon account deletion. Wanted may retain data on the Users’
                          invoices and identification information for a longer period of time (but no longer than the
                          applicable prescription period), so that Wanted can prove fulfillment of its obligations
                          towards the participants in the B2C market and towards the relationships between
                          Wanted and its clients, which is one of the Company’s primary business operations.
                        </p>
                        <p class="mb-8">
                          Furthermore, Wanted retains the right and obligation to keep the data of a person who
                          has breached the terms of use of the website and has been banned or is susceptible to
                          being banned for such reasons (including, but not limited to: hate speech, verbal
                          harassment, threats to other users, etc.). If the inappropriate action constitutes a crime,
                          Wanted is obliged to keep records of this in order to fulfill its legal obligation to assist the
                          public authorities and provide information related to the crime or illegal conduct in
                          question (as is set out above).
                        </p>
                        <p class="mb-8 text-gray-900">
                          13. Privacy policy changes and amendments
                        </p>
                        <p class="mb-8">
                          We reserve the right to change our data protection practices and to update and amend
                          this policy at any time. For this reason, we invite you to consult the policy regularly. This
                          data protection policy is current as of the date indicated as the "Last amendment" of the
                          document.
                        </p>
                      </div>
                      <!--end::Description-->
                    </div>
                    <!--end::Post content-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Layout-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Post card-->
          </div>
          <!--end::Content container-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Content wrapper-->
    </div>
  </div>
</template>
<style>
table {
  border-collapse: collapse;
}
table, th, td {
  border: 1px solid black;
  padding: 10px;
}
</style>
<script lang="js">
import {defineComponent} from "vue";

export default defineComponent({
  name: "privacy-policy",
});
</script>
